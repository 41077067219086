.container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 49px - link policy*/
    height: calc(100vh - 49px);  
    height: calc(var(--app-height) - 49px);
}
.btn{
    width: 300px;
    height: 70px;
}
.modalSuccess{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 400px;
    background: ghostwhite;
    box-shadow: 10px 10px 8px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border-radius: 5px;
}
.hidden {
    opacity: 0;
    pointer-events: none}
