
.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.policy {
    margin-bottom: 24px;
    white-space: nowrap;
    color: #1a73e8;}